<template>
  <div>
    <div v-if="num > 0" class="service-list">
      <template v-for="(item, index) in list">
        <div
          class="service-item"
          :key="index"
          v-if="item.items && item.items.length"
          @click="hanldeClick(item)"
        >
          <div class="service-icon">
            <PolicyIcon :icon="item.items[0].iconUrl" />
          </div>
          <div class="service-title">{{ item.name }}</div>
        </div>
      </template>
    </div>
    <van-empty v-else image-size="100" description="暂无数据" />
    <van-popup
      v-model="isShowGroup"
      position="bottom"
      :style="{ height: '30%' }"
    >
      <div style="padding: 0.2rem">
        <div class="service-list">
          <template v-for="(item, index) in groupList">
            <div
              class="service-item"
              :key="index"
              @click="navToTodoDetail(item)"
            >
              <div class="service-icon">
                <PolicyIcon :icon="item.iconUrl" />
              </div>
              <div class="service-title">
                {{ item.itemAlias || item.itemName }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import PolicyIcon from "@/components/PolicyIcon";
export default {
  data() {
    return {
      isShowGroup: false,
      groupList: [],
    };
  },
  components: { PolicyIcon },
  props: {
    list: Array,
    num: Number,
  },
  computed: {},
  filters: {},
  mounted() {},
  methods: {
    hanldeClick(item) {
      if (1 == item.items.length && 2 == item.eject) {
        this.$router.push({
          path: `/guideDetail`,
          query: {
            itemId: item.items[0].itemId,
          },
        });
        return;
      }
      this.groupList = item.items;
      this.isShowGroup = true;
    },
    navToTodoDetail({ itemId }) {
      this.$router.push({
        path: `/guideDetail`,
        query: {
          itemId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.service-list {
  margin-top: 0.2rem;
  display: flex;
  flex-wrap: wrap;
  .service-item {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0.4rem;

    .service-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 0.88rem;
      height: 0.88rem;
      background: #eff5f9;
      border-radius: 50%;
      margin-bottom: 0.2rem;
    }
    .service-title {
      text-align: center;
      font-size: 0.28rem;
      color: #333333;
      line-height: 0.4rem;
      text-overflow: ellipsis;
      display: -webkit-box;
      word-break:break-all
      // -webkit-box-orient: vertical;
      // -webkit-line-clamp: 2;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
  }
}
</style>
